import { gql } from '@apollo/client/core';

// eslint-disable-next-line import/prefer-default-export
export const ACTIVE_REMINDER_FRAGMENT = gql`
  fragment ActiveReminderFragment on ReminderType {
    uuid
    createdBy {
      uuid
    }
    owner {
      uuid
      user {
        uuid
        email
        getName
      }
    }
    note
    datetime
    priority
    isCompleted
  }
`;

export const PAGINATED_REMINDERS = gql`
  query paginatedReminders($first: Int, $skip: Int, $order: String, $relatedObjectType: String, $owners: [String], $relatedObjectId: Int, $isCompleted: String, $dateRange: [String], $search: String) {
    paginatedReminders(first: $first, skip: $skip, order: $order, relatedObjectType: $relatedObjectType, owners: $owners, relatedObjectId: $relatedObjectId, isCompleted: $isCompleted, dateRange: $dateRange, search: $search) {
      results {
        id
        uuid
        createdBy {
          uuid
        }
        owner {
          uuid
          user {
            uuid
            email
            getName
          }
        }
        note
        datetime
        priority
        isCompleted
        studentName
        preceptorName
        relatedObjectId
        relatedObjectUid
        relatedObjectUrl
        relatedObjectType
        relatedObjectName
      }
      totalCount
    }
  }
`;
