import { gql } from '@apollo/client';

export const ME = gql`
  query me {
    me {
      uuid
      firebaseUid
      hasRole
      emailVerified
      isStaff
      isSuperuser
      clinicCoordinator {
        uuid
      }
      student {
        id
        uuid
        profile {
          avatar
        }
      }
      preceptor {
        id
        uuid
        profile {
          avatar
        }
      }
      lastUsedRole {
        key
        value
      }
      wizardState {
        isWizardCompleted
        lastWizardStep
      }
    }
  }
`;

export const ALL_USER_EMAILS_QUERY = gql`
  query allUserEmails {
    allUserEmails {
      emailAddress
      fullName
    }
  }
`;
